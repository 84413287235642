import React, { useState, useRef, useEffect } from 'react';
import '../styles/home.css';
import { Link } from 'react-router-dom';
import defaultAvatar from '../components/wlcpage/headavatar.png';
import Modal from '../components/modal.js';
import fotlogo from '../imgs/fotlogo.svg';
import fotlogo2 from '../imgs/fotlogo2.svg';
import fotlogo3 from '../imgs/fotlogo3.svg';
import { TonConnectUIProvider, useTonConnectUI, useTonWallet, toUserFriendlyAddress } from '@tonconnect/ui-react';
import { useTaskContext } from '../context/TaskContext';
import { useSpring, animated } from '@react-spring/web';
import axios from 'axios';

function Leaders() {
  const [profileEditModalVisible, setProfileEditModalVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [taskInfo, setTaskInfo] = useState('');
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const { tasksVisible, handleHideTasks } = useTaskContext();
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const [notifyText, setNotifyText] = useState('');

  const [username, setUsername] = useState('');
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [balance, setBalance] = useState(0);
  const [friendsCount, setFriendsCount] = useState(0);
  const [leaders, setLeaders] = useState([]);
  const [lang, setLang] = useState('en'); // По умолчанию английский
  const baseURL = "https://app.jettonwallet.com";
  const [userId, setUserId] = useState(null);

  const [springProps, api] = useSpring(() => ({ y: 0, config: { tension: 300, friction: 20 } }));
  const scrollRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);

  // Тексты на двух языках
  const translations = {
    en: {
      someText: 'Some text Some text Some text Some text Some text Some text',
      friends: 'friends',
      coins: 'coins',
      tasks: 'Tasks',
      leaders: 'Leaders',
      home: 'Home',
      contact: 'Contact',
      about: 'About',
      close: '×',
      tasksHeader: 'Tasks',
    },
    ru: {
      someText: 'Текст Текст Текст Текст Текст Текст',
      friends: 'друзей',
      coins: 'монет',
      tasks: 'Задачи',
      leaders: 'Лидеры',
      home: 'Домой',
      contact: 'Контакт',
      about: 'О нас',
      close: '×',
      tasksHeader: 'Задачи',
    },
  };

  // Получение текущего языка
  const t = translations[lang];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const tg = window.Telegram.WebApp;
        const userId = tg?.initDataUnsafe?.user?.id || 1052552985;

        if (userId) {
          const response = await axios.get(`${baseURL}/api2/user/${userId}`);
          const userData = response.data;
          const settingsResponse = await axios.get(`${baseURL}/api2/setting`);
          const settingsData = settingsResponse.data;
          const notificationText = userData.lang === 'en' ? settingsData.notify_text_en : settingsData.notify_text_ru;
          setNotifyText(notificationText || '');  // Устанавливаем текст уведомления
          setUsername(userData.name);
          setAvatar(userData.avatar_link ? `${baseURL}${userData.avatar_link}` : defaultAvatar);
          setBalance(userData.balance);
          setFriendsCount(userData.refferals.length);
          setLang(userData.lang); // Устанавливаем язык
        } else {
          console.error('Не удалось получить user_id из Telegram WebApp');
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных пользователя:', error);
      }      // Fetch settings data

    };

    const fetchLeaders = async () => {
      try {
        const response = await axios.get(`${baseURL}/api2/leader`);
        const sortedLeaders = response.data.leaders.sort((a, b) => b.coins - a.coins);
        setLeaders(sortedLeaders);
      } catch (error) {
        console.error('Ошибка при загрузке данных лидеров:', error);
      }
    };

    fetchUserData();
    fetchLeaders();
  }, []);

  const handleScroll = (e) => {
    const scrollElement = e.target;
    const scrollTop = scrollElement.scrollTop;
    const maxScrollTop = scrollElement.scrollHeight - scrollElement.clientHeight;

    if (scrollTop >= maxScrollTop) {
      setIsAtBottom(true);
      api.start({ y: (scrollTop - maxScrollTop) * 0.3 });
    } else {
      setIsAtBottom(false);
      api.start({ y: 0 });
    }
  };

  const handleScrollRelease = () => {
    if (isAtBottom) {
      api.start({ y: 0 });
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    scrollElement.addEventListener('scroll', handleScroll);
    scrollElement.addEventListener('touchend', handleScrollRelease);
    scrollElement.addEventListener('mouseup', handleScrollRelease);

    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
      scrollElement.removeEventListener('touchend', handleScrollRelease);
      scrollElement.removeEventListener('mouseup', handleScrollRelease);
    };
  }, [isAtBottom]);

  const handleClaimClick = (info) => {
    setTaskInfo(info);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConnectWallet = async () => {
    try {
      let wallet = await tonConnectUI.connectWallet();
      await axios.post(`${baseURL}/api2/user/update-wallet`, {
        user_id: userId,
        wallet: toUserFriendlyAddress(wallet.account.address)
      });
    } catch (error) {
      console.error('Ошибка подключения кошелька:', error);
    }
  };

  const handleProfileEditClick = () => {
    setProfileEditModalVisible(true);
  };

  const closeProfileEditModal = () => {
    setProfileEditModalVisible(false);
  };

  const handleWalletClick = () => {
    setWalletModalVisible(true);
  };

  const handleCopyWallet = () => {
    if (wallet?.account) {
      navigator.clipboard.writeText(wallet.account.address);
      setWalletModalVisible(false);
      alert('Wallet number copied!');
    }
  };

  const handleDisconnectWallet = async () => {
    try {
      await tonConnectUI.disconnect();
      setWalletModalVisible(false);
    } catch (error) {
      console.error('Ошибка отключения кошелька:', error);
    }
  };

  const maskWallet = (address) => {
    if (!address) return '';
    return `${address.slice(2, 5)}****${address.slice(-2)}`;
  };

  const closeWalletModal = () => {
    setWalletModalVisible(false);
  };

  return (
    <TonConnectUIProvider manifestUrl="https://jettocoinwebapp.vercel.app/tonconnect-manifest.json">
      <div className="container">
        <div className='ttt'>
          <p>{t.someText}</p> {/* Использование перевода */}
        </div>
        <div className='headerr'>
          <div className='nae'>
            <span className='nameava'>
              <span className='imgheader'>
                <Link to='/stylesy'><img src={avatar} alt='Avatar' /></Link>
                <p>{username}</p>
              </span>
              <span className='frenhead'>
                <p>{friendsCount} {t.friends}</p>
              </span>
            </span>
            <span className='headbtns'>
              <div className='coinss'>
                <p>{parseInt(balance).toLocaleString()} {t.coins}</p>
              </div>
            </span>
          </div>
        </div>

        {tasksVisible && (
          <div className='tasks'>
            <h1>
              {t.tasks}
              <button onClick={handleHideTasks} className='close-btn'>×</button>
            </h1>
            <p>{notifyText}</p>
          </div>
        )}

        <div className='maincontent leadhei'>
          <div className='switchfix'>
            <div className='switches'>
              <Link to='/' className='linkbtn'><button className='btn2'>{t.tasks}</button></Link>
              <Link to='/Leaders' className='linkbtn'><button className='btn3'>{t.leaders}</button></Link>
            </div>
          </div>
          <animated.div
            className='switchcontent padd'
            ref={scrollRef}
            style={{ transform: springProps.y.to(y => `translateY(${y}px)`) }}
          >
            {leaders.map((leader, index) => (
              <div className='tasking' key={index}>
                <img className='tskimg' src={leader.avatar_link ? `https://app.jettonwallet.com${leader.avatar_link}` : defaultAvatar} alt='Leader Avatar' />
                <div className='tskk'>
                  <p className='tsk'>{leader.name}</p>
                  <p className='tsk coins-int'>{parseInt(leader.coins).toLocaleString()} {t.coins}</p>
                </div>
              </div>
            ))}
          </animated.div>

          <div className={`blur-overlay ${showModal || walletModalVisible || profileEditModalVisible ? 'show' : ''}`} />

        </div>
        <div className='fot'>
          <div className='fotcont'>
            <Link to='/'><button className='activebtn'><img src={fotlogo} alt={t.home}/></button></Link>
            <Link to='/Contact'><button><img src={fotlogo2} alt={t.contact}/></button></Link>
            <Link to='/about'><button><img src={fotlogo3} alt={t.about}/></button></Link>
          </div>
        </div>
      </div>
    </TonConnectUIProvider>
  );
}

export default Leaders;
