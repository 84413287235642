import React, { useState, useEffect } from 'react';
import '../styles/home.css';
import avatar from '../components/wlcpage/headavatar.png'; // Default avatar
import { Link } from 'react-router-dom';
import fotlogo from '../imgs/fotlogo.svg';
import fotlogo2 from '../imgs/fotlogo2.svg';
import fotlogo3 from '../imgs/fotlogo3.svg';
import Modal from '../components/modal2.js';
import '../components/wlcpage/modal2.css';
import axios from 'axios';

function Contact() {
    const [walletModalVisible, setWalletModalVisible] = useState(false);
    const [profileEditModalVisible, setProfileEditModalVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [claimed, setClaimed] = useState(false);
    const [balance, setBalance] = useState(0);
    const [referrals, setReferrals] = useState([]);
    const [refferallClaim, setRefferallClaim] = useState(0);
    const [claimConditionMet, setClaimConditionMet] = useState(false);
    const [currentReferrals, setCurrentReferrals] = useState(0);
    const [totalTasks, setTotalTasks] = useState(0); // Для общего количества задач
    const [lang, setLang] = useState('en'); // По умолчанию английский

    const translations = {
        en: {
            myReferrals: 'My referrals',
            noReferrals: 'NO REFERRALS YET',
            availableClaim: 'Available for the claim',
            profit: 'tasks',
            claimed: 'Claimed',
            claim: 'Claim',
            claimText: 'By inviting friends, you get 10% of their balance and 2.5% of their referrals\' balance',
            referralLink: 'Referral link',
            copy: 'Copy',
            share: 'Share',
            tasksCompleted: 'Tasks',
            profitt: 'profit'
        },
        ru: {
            myReferrals: 'Мои рефералы',
            noReferrals: 'РЕФЕРАЛОВ ЕЩЕ НЕТ',
            availableClaim: 'Доступно для получения',
            profit: 'заданий',
            claimed: 'Получено',
            claim: 'Получить',
            claimText: 'Пригласив друзей, вы получаете 10% от их баланса и 2,5% от баланса их рефералов',
            referralLink: 'Реферальная ссылка',
            copy: 'Копировать',
            share: 'Поделиться',
            tasksCompleted: 'Заданий',
            profitt: 'профит'
        },
    };

    // Получение текущего перевода
    const t = translations[lang];
    
    const tg = window.Telegram.WebApp;
    const userId = tg?.initDataUnsafe?.user?.id || 1052552985;

    const baseURL = "https://app.jettonwallet.com"; // Ensure the base URL is set correctly

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Получаем данные пользователя
                const response = await axios.get(`${baseURL}/api2/user/${userId}`);
                const userData = response.data;

                // Загружаем все задачи
                const tasksResponse = await axios.get(`${baseURL}/api2/tasks/${userId}`);
                const loadedTasks = tasksResponse.data.tasks;

                // Устанавливаем общее количество задач
                setTotalTasks(loadedTasks.length);

                const referralList = userData.refferals || [];
                setReferrals(referralList);

                setRefferallClaim(parseFloat(userData.refferall_claim).toFixed(1));
                setClaimConditionMet(parseFloat(userData.refferall_claim) > 0);
                setClaimed(parseFloat(userData.refferall_claim) === 0);
                setCurrentReferrals(referralList.length);
                setLang(userData.lang);
                localStorage.setItem('claimed', parseFloat(userData.refferall_claim) === 0);
                localStorage.setItem('balance', userData.balance);
                localStorage.setItem('referrals', JSON.stringify(referralList));
            } catch (error) {
                console.error('Ошибка при загрузке данных пользователя:', error);
            }
        };

        if (userId) {
            fetchData();
        }
    }, [userId]);

    const handleClaimClick = async () => {
        try {
            await axios.get(`${baseURL}/api2/claim-reff/${userId}`);
            setClaimed(true);
            setClaimConditionMet(false);
            setBalance(prevBalance => (parseFloat(prevBalance) + parseFloat(refferallClaim)).toFixed(1));
        } catch (error) {
            console.error('Ошибка при получении вознаграждения:', error);
        }
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(`https://t.me/JWApp_bot?start=${userId}`)
            .then(() => {
                setShowModal(true);
            })
            .catch(err => {
                console.error('Не удалось скопировать ссылку:', err);
            });
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="container">
            <div className='maincontent refcontenter'>
                <div className='switchfix'>
                    <div className='reflimit'>
                        <p className='refh1 myref'>{t.myReferrals}</p>
                        <span>{currentReferrals}/10</span>
                    </div>
                    <div className='switchcontact'>
                        {referrals.length > 0 ? (
                            referrals.map((referral, index) => {
                                const avatarURL = referral.avatar_link 
                                    ? (referral.avatar_link.startsWith('http') 
                                        ? referral.avatar_link 
                                        : `${baseURL}${referral.avatar_link}`) 
                                    : avatar;

                                // Количество выполненных задач рефералом
                                const tasksDone = referral.profit || 0;

                                return (
                                    <div className='tasking' key={index}>
                                        <img src={avatarURL} width='36px' alt='Avatar' className='amg' />
                                        <div className='tskk tsauto'>
                                            <p className='tskkk'>{referral.name}</p>
                                        </div>
                                        <div className='valuetask'>
                                            <g>{t.tasksCompleted}:</g>
                                            <p className='fivetoten'>{tasksDone} / {totalTasks}</p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p className='no-referrals refh1'>{t.noReferrals}</p>
                        )}
                    </div>
                </div>
                <div className={`blur-overlay ${showModal || walletModalVisible || profileEditModalVisible ? 'show' : ''}`} />
            </div>

            <div className='headerr'>
                <div className='nae'>
                    <p className='refh1'>{t.availableClaim}</p>
                    <span className='headbtnsref'>
                        <p className='refhh1'>{refferallClaim} {t.profitt}</p>
                        <button
                            className={`btnref ${!claimConditionMet || claimed ? 'inactive' : ''}`}
                            onClick={handleClaimClick}
                            disabled={!claimConditionMet || claimed}
                        >
                            <p>{claimed ? t.claimed : t.claim}</p>
                        </button>
                    </span>
                    <p className='bbh2'>{t.claimText}</p>
                </div>
            </div>

            <div className='refcontainer'>
                <p className='refh1'>{t.referralLink}</p>
                <span className='btnsharegroup'>
                    <button className='shareclaimbtn' onClick={handleCopyClick}><p>{t.copy}</p></button>
                    <Link to={`https://telegram.me/share/url?url=https://t.me/JWApp_bot?start=${userId}`}>
                        <button className='sharebtn'><p>{t.share}</p></button>
                    </Link>
                </span>
            </div>

            <div className='fot'>
                <div className='fotcont'>
                    <Link to='/'><button ><img src={fotlogo} alt='Home'></img></button></Link>
                    <Link to='/Contact' ><button className='activebtn'><img src={fotlogo2} alt='Contact'></img></button></Link>
                    <Link to='/about'><button><img src={fotlogo3} alt='About'></img></button></Link>
                </div>
            </div>

            <Modal show={showModal} handleClose={handleCloseModal} />
        </div>
    );

}

export default Contact;
