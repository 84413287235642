import React, { useState, useEffect } from 'react';
import './wlc.css';
import { Link } from 'react-router-dom';
import tst from './trst.png';
import axios from 'axios';

function Stylesy() {
  const [avatars, setAvatars] = useState([]);  // Список аватаров будет загружаться с сервера
  const [selectedAvatar, setSelectedAvatar] = useState(null); // Выбранная аватарка
  const [nickname, setNickname] = useState('');
  const tg = window.Telegram.WebApp;  // Инициализация Telegram WebApp
  const id = tg?.initDataUnsafe?.user?.id || 1;  // Получение user_id из Telegram WebApp

  useEffect(() => {
    tg.expand();  // Разворачиваем WebApp

    // Загружаем сохранённые никнейм и аватар из localStorage
    const savedNickname = localStorage.getItem('nickname');
    const savedAvatar = localStorage.getItem('selectedAvatar');

    if (savedNickname) {
      setNickname(savedNickname);
    } else if (tg?.initDataUnsafe?.user?.username) {
      setNickname(tg.initDataUnsafe.user.username);
    }

    // Загрузка аватаров с сервера
    axios.get('https://app.jettonwallet.com/api2/avatar')
      .then(response => {
        const baseURL = "https://app.jettonwallet.com"; // Новый базовый URL
        const updatedAvatars = response.data.avatars.map(avatar => ({
          ...avatar,
          img: `${baseURL}${avatar.img}` // Добавляем базовый URL перед путём
        }));
        setAvatars(updatedAvatars);

        // Устанавливаем первую аватарку как выбранную по умолчанию
        if (updatedAvatars.length > 0 && !savedAvatar) {
          setSelectedAvatar(updatedAvatars[0].img);
        } else if (savedAvatar) {
          setSelectedAvatar(savedAvatar);
        }
      })
      .catch(error => {
        console.error('Ошибка при загрузке аватаров:', error);
      });
  }, [tg]);

  const handleAvatarClick = (avatarUrl) => {
    setSelectedAvatar(avatarUrl); // Обновляем выбранную аватарку
  };

  const handleSave = async () => {
    try {
      // Отправляем данные на сервер
      await axios.post('https://app.jettonwallet.com/api2/update-avatar', {
        user_id: id,  
        name: nickname,
        avatar: selectedAvatar.replace('https://app.jettonwallet.com', '')
      });

      // Сохраняем данные в localStorage после успешного сохранения на сервере
      localStorage.setItem('nickname', nickname);
      localStorage.setItem('selectedAvatar', selectedAvatar);

      alert('Данные успешно сохранены!');
    } catch (error) {
      console.error('Ошибка при сохранении данных:', error);
      alert('Произошла ошибка при сохранении данных');
    }
  };

  return (
    <div className='wrapper'>
      <div className='headerstyle'>
        <div className='headerss'>
          <div className='headavatar'>
            <img src={selectedAvatar} alt='Avatar' /> {/* Показываем выбранную аватарку */}
          </div>
          <div className='headname'>
            <p className='uname'>Your name</p>
            <div className='name'>
              <input
                type='text'
                placeholder='Nickname'
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='stylecontainer'>
        <p>You can choose a different avatar</p>
        <div className='avatars'>
          {avatars.map((avatar, index) => (
            <button
              key={index}
              className={`avatar-button ${selectedAvatar === avatar.img ? 'selected' : ''}`}
              onClick={() => handleAvatarClick(avatar.img)}
            >
              <img className='baseimg' src={avatar.img} alt='' />
              {selectedAvatar === avatar.img && (
                <img className='activecheck' src={tst} alt='' />
              )}
            </button>
          ))}
        </div>
      </div>
      <div className='saved'>
        <Link to='/'>
          <button onClick={handleSave}>
            <p>Save</p>
          </button>
        </Link>
      </div>
      <div className='fotlogo'>
        
      </div>
    </div>
  );
}

export default Stylesy;
