import { TonConnectUIProvider } from '@tonconnect/ui-react';
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom'; // Добавляем useNavigate
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Wlc1 from './components/wlcpage/wlc1';
import Leaders from './pages/Leaders';
import Stylesy from './components/wlcpage/stylesy'; 
import './App.css';
import { TaskProvider } from './context/TaskContext';

function App() {
    const navigate = useNavigate(); // используем useNavigate для навигации

    useEffect(() => {
        const hasVisited = localStorage.getItem('hasVisited'); // Проверка, был ли пользователь на сайте

        if (!hasVisited) {
            localStorage.setItem('hasVisited', 'true'); // Ставим метку, что пользователь уже был на сайте
            navigate('/wlc1'); // Перенаправляем на Wlc1
        }
    }, [navigate]);

    return (
        <TaskProvider>
            <TonConnectUIProvider manifestUrl="https://jettocoinwebapp.vercel.app/tonconnect-manifest.json">
                <>
                    <Routes>
                        <Route exact path="/wlc1" element={<Wlc1 />} />
                        <Route exact path="/stylesy" element={<Stylesy />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/leaders" element={<Leaders />} />
                        <Route path="/manifest" element={<manifest />} />
                    </Routes>
                </>
            </TonConnectUIProvider>
        </TaskProvider>
    );
}

export default App;
