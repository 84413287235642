import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/home.css';
import { Link } from 'react-router-dom';
import arrow from '../imgs/arrow.svg';
import term from '../imgs/terms.svg';
import logofot from '../imgs/logofot.svg';
import fotlogo from '../imgs/fotlogo.svg';
import fotlogo2 from '../imgs/fotlogo2.svg';
import fotlogo3 from '../imgs/fotlogo3.svg';

function About() {
    const [tasksCompleted, setTasksCompleted] = useState(0);
    const [friendsInvited, setFriendsInvited] = useState(0);
    const [settings, setSettings] = useState({});
    const [notificationEnabled, setNotificationEnabled] = useState(false);
    const tg = window.Telegram.WebApp;
    const user_id = tg?.initDataUnsafe?.user?.id || 1052552985;
    const baseURL = "https://app.jettonwallet.com";
    const translations = {
        en: {
            settings: 'Settings',
            changeLanguage: 'Change the language',
            language: 'English',
            changeExchange: 'Change the exchange',
            exchange: 'Ton Wallet',
            notification: 'Notification',
            statistics: 'Statistics',
            tasksCompleted: 'Tasks completed',
            friendsInvited: 'Friends invited',
            anyQuestions: 'Any questions?',
            support: 'Support',
            termsOfUse: 'Terms of Use',
            userAgreement: 'User Agreement',
            emailCopied: 'Email copied to clipboard!',
        },
        ru: {
            settings: 'Настройки',
            changeLanguage: 'Изменить язык',
            language: 'Русский',
            changeExchange: 'Изменить кошелек',
            exchange: 'Ton Wallet',
            notification: 'Уведомления',
            statistics: 'Статистика',
            tasksCompleted: 'Задачи выполнены',
            friendsInvited: 'Приглашено друзей',
            anyQuestions: 'Есть вопросы?',
            support: 'Поддержка',
            termsOfUse: 'Условия использования',
            userAgreement: 'Пользовательское соглашение',
            emailCopied: 'Электронная почта скопирована в буфер обмена!',
        }
    };

    const handleLanguageChange = async () => {
        const newLang = lang === 'en' ? 'ru' : 'en';
        setLang(newLang); // Update the language in the state
    
        try {
            await axios.post(`${baseURL}/api2/change-lang`, {
                user_id: user_id,
                lang: newLang
            });
        } catch (error) {
            console.error('Ошибка при изменении языка:', error);
        }
    };
    
    

    const handleNotificationToggle = async (event) => {
        const push = event.target.checked;
        setNotificationEnabled(push); // Обновляем состояние
        try {
            await axios.post(`${baseURL}/api2/push`, {
                user_id: user_id,
                push: push
            });
        } catch (error) {
            console.error('Ошибка при отправке данных уведомлений:', error);
        }
    };
    



    const [lang, setLang] = useState('en'); // По умолчанию английский
    const t = translations[lang];

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const tg = window.Telegram.WebApp;
                const userId = tg?.initDataUnsafe?.user?.id || 1052552985;
    
                if (userId) {
                    const userResponse = await axios.get(`${baseURL}/api2/user/${userId}`);
                    const userData = userResponse.data;
    
                    setLang(userData.lang); // Устанавливаем язык
    
                    // Загружаем состояние уведомлений
                    const notificationResponse = await axios.get(`${baseURL}/api2/user/${userId}`);
                    setNotificationEnabled(notificationResponse.data.push || false);
                } else {
                    console.error('Не удалось получить user_id из Telegram WebApp');
                }
            } catch (error) {
                console.error('Ошибка при загрузке данных пользователя:', error);
            }
        };
    
        fetchUserData();
    }, []);
    

    useEffect(() => {
        const fetchStatisticsAndSettings = async () => {
            try {
                const userStatsResponse = await axios.get(`https://app.jettonwallet.com/api2/user-stat/${user_id}`);
                const userStatsData = userStatsResponse.data;

                setTasksCompleted(userStatsData.taskComplited || 0);
                setFriendsInvited(userStatsData.userFrend || 0);

                const settingsResponse = await axios.get('https://app.jettonwallet.com/api2/setting');
                setSettings(settingsResponse.data);
            } catch (error) {
                console.error('Error fetching user statistics or settings:', error);
            }
        };

        fetchStatisticsAndSettings();
    }, [user_id]);

    const handleCopyEmail = () => {
        navigator.clipboard.writeText(settings.mail || 'manager@jettonwallet.com')
            .then(() => {
                alert(t.emailCopied);
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <div className="container">
            <div className='setings'>
                <p className='refh1 btntop'>{t.settings}</p>
                <button className='setbtn' onClick={handleLanguageChange}>
                    <span>
                        <p className='hh1set'>{t.changeLanguage}</p>
                        <p className='hh2set'>{t.language}</p>
                    </span>
                    <img src={arrow} alt="Arrow" />
                </button>


                <div className="notification-container">
    <span className="notification-text">{t.notification}</span>
    <label className="switch">
        <input type="checkbox" checked={notificationEnabled} onChange={handleNotificationToggle} />
        <span className="slider"></span>
    </label>
</div>


            </div>

            <div className='headerr'>
                <div className='nae'>
                    <p className='refh1 btntop'>{t.statistics}</p>
                    <span className='headbtnsset'>
                        <div className='btnset '>
                            <p className='piset'>{t.tasksCompleted}</p>
                            <p className='pset'>{tasksCompleted}</p>
                        </div>
                        <div className='btnset'>
                            <p className='piset'>{t.friendsInvited}</p>
                            <p className='pset'>{friendsInvited}/10</p>
                        </div>
                    </span>
                </div>
            </div>

            <div className='refcontainer'>
                <p className='refh1'>{t.anyQuestions}</p>
                <span className='btnsharegroupsup'>
                    <div className='btncopyemail'>
                        <button className='copy-email' onClick={handleCopyEmail}>
                            {settings.mail || 'manager@jettonwallet.com'}
                        </button>
                        <Link to={settings.support || 'https://telegram.org/support?setln=ru'}>
                            <button className='supbtn'>
                                <p>{t.support}</p>
                            </button>
                        </Link>
                    </div>
                    <div className='term'>
                        <Link to={settings.termofuse || '#'}>
                            <button className='terbtn'>
                                <span>
                                    <p className='hh1set'>{t.termsOfUse}</p>
                                </span>
                            </button>
                        </Link>
                        <Link to={settings.useragreement || '#'}>
                            <button className='terbtn'>
                                <span>
                                    <p className='hh1set'>{t.userAgreement}</p>
                                </span>
                            </button>
                        </Link>
                    </div>
                </span>
            </div>

            <div className='fot'>
                <img src={logofot} className='logofot' alt="Logo" />
                <div className='fotcont'>
                    <Link to='/'><button><img src={fotlogo} alt='Home'></img></button></Link>
                    <Link to='/Contact'><button><img src={fotlogo2} alt='Contact'></img></button></Link>
                    <Link to='/about'><button className='activebtn'><img src={fotlogo3} alt='About'></img></button></Link>
                </div>
            </div>
        </div>
    );
}

export default About;
