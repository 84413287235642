import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from '@react-spring/web';
import '../styles/home.css';
import defaultAvatar from '../components/wlcpage/headavatar.png';
import { Link, useNavigate } from 'react-router-dom';
import fotlogo from '../imgs/fotlogo.svg';
import fotlogo2 from '../imgs/fotlogo2.svg';
import fotlogo3 from '../imgs/fotlogo3.svg';
import Modal from '../components/modal.js';
import wltlogo from '../imgs/wallet.svg';
import {
  TonConnectUIProvider,
  useTonConnectUI,
  useTonWallet,
  useTonAddress,
  toUserFriendlyAddress
} from '@tonconnect/ui-react';
import { useTaskContext } from '../context/TaskContext.js';
import axios from 'axios';

function Home() {
  const [notifyText, setNotifyText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [taskInfo, setTaskInfo] = useState('');
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [filter, setFilter] = useState('all');
  const [profileEditModalVisible, setProfileEditModalVisible] = useState(false);
  const { tasksVisible, handleHideTasks } = useTaskContext();
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [balance, setBalance] = useState(0);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [referralCount, setReferralCount] = useState(0);
  const [userId, setUserId] = useState(null);
  const [filteredTasks, setFilterTasks] = useState([])
  const baseURL = "https://app.jettonwallet.com";
  
  
  const [springProps, api] = useSpring(() => ({
    y: 0,
    config: { tension: 300, friction: 20 },
  }));

  const translations = {
    en: {
      someText: 'Some text Some text Some text Some text Some text Some text',
      friends: 'friends',
      coins: 'coins',
      connectWallet: 'Connect wallet',
      tasksHeader: 'Tasks',
      manualVerification: 'Manual verification',
      socialActivity: 'Social activity',
      copy: 'copy',
      tasks: 'Tasks',
      leaders: 'Leaders',
      close: 'close',
      success: 'success',
      claimed: 'Claimed',
      incheck: 'in check',
      Processing: 'Processing',
      decline: 'decline',
      Declined: 'Declined',
      toclaim: 'to claim',
      inactive: 'inactive',
      all: 'All',

    },
    ru: {
      someText: 'Текст Текст Текст Текст Текст Текст',
      friends: 'друзей',
      coins: 'монет',
      connectWallet: 'Подключить кошелек',
      tasksHeader: 'Задачи',
      manualVerification: 'Руч.Проверка',
      socialActivity: 'Соц.Активность',
      copy: 'копировать',
      tasks: 'Задачи',
      leaders: 'Лидеры',
      close: 'закрыть',
      success: 'успешно',
      claimed: 'Получено',
      incheck: 'в проверке',
      Processing: 'Обработка',
      decline: 'отклонить',
      Declined: 'Отклонено',
      toclaim: 'забрать',
      inactive: 'неактивно',
      all: 'Все',
    },
  };


  const scrollRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [lang, setLang] = useState('en'); // Default to English
  const t = translations[lang];
  useEffect(() => {
    const tg = window.Telegram.WebApp;
    const id = tg?.initDataUnsafe?.user?.id || 1052552985;
    setUserId(id);

      const fetchData = async () => {
        try {
          // Fetch user data
          const response = await axios.get(`${baseURL}/api2/user/${id}`);
          const userData = response.data;
    
          setUsername(userData.name);
          setAvatar(userData.avatar_link ? `${baseURL}${userData.avatar_link}` : defaultAvatar);
          setBalance(userData.balance);
          setReferralCount(userData.refferals.length);
          setLang(userData.lang); // Устанавливаем язык
    
          if (!userData.name) {
            navigate('/wlc1');
          }
    
          // Fetch tasks data
          const tasksResponse = await axios.get(`${baseURL}/api2/tasks/${id}`);
          const loadedTasks = tasksResponse.data.tasks;
    
          // Sort tasks by priority (descending order)
          loadedTasks.sort((a, b) => b.priority - a.priority);
    
          // Filter tasks based on the selected filter and the user's language
          const filtered = loadedTasks.filter(task => {
            if (filter === 'all') return true;
            if (filter === 'social activity') return task.type === false;
            if (filter === 'manual verification') return task.type === true;
          });
    
          // Set the tasks, and map the correct text field based on the language
          const mappedTasks = filtered.map(task => ({
            ...task,
            text: lang === 'en' ? task.text : task.text_ru,
          }));
    
          setTasks(mappedTasks);
          setFilterTasks(mappedTasks);
    
          // Fetch settings data
          const settingsResponse = await axios.get(`${baseURL}/api2/setting`);
          const settingsData = settingsResponse.data;
          const notificationText = userData.lang === 'en' ? settingsData.notify_text_en : settingsData.notify_text_ru;
          setNotifyText(notificationText || '');
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
        }
      };
    
      if (id) {
        fetchData();
      }
    }, [filter, lang]);

  const handleScroll = (e) => {
    const scrollElement = e.target;
    const scrollTop = scrollElement.scrollTop;
    const maxScrollTop = scrollElement.scrollHeight - scrollElement.clientHeight;

    if (scrollTop >= maxScrollTop) {
      setIsAtBottom(true);
      api.start({ y: (scrollTop - maxScrollTop) * 0.3 });
    } else {
      setIsAtBottom(false);
      api.start({ y: 10 });
    }
  };

  const handleScrollRelease = () => {
    if (isAtBottom) {
      api.start({ y: 10 });
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    scrollElement.addEventListener('scroll', handleScroll);
    scrollElement.addEventListener('touchend', handleScrollRelease);
    scrollElement.addEventListener('mouseup', handleScrollRelease);

    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
      scrollElement.removeEventListener('touchend', handleScrollRelease);
      scrollElement.removeEventListener('mouseup', handleScrollRelease);
    };
  }, [isAtBottom]);

  const handleClaimClick = async (taskId, taskType, reward, link) => {
    try {
      // Открываем ссылку в новой вкладке
      if (link) {
        const validLink = link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
  
        // Используем web_app_open_link для открытия ссылки в приложении Telegram
        if (window.Telegram?.WebApp?.openLink) {
          window.Telegram.WebApp.openLink(validLink);
        } else {
          // Если метод не доступен, откроем ссылку в новой вкладке как резервный вариант
          window.open(validLink, '_blank');
        }
      }
  
      // Отправляем запрос на выполнение задания после открытия ссылки
      const postData = {
        user_id: userId,
        task_id: taskId,
      };
  
      console.log("Data being sent:", postData);
  
      const response = await axios.post('https://app.jettonwallet.com/api2/done-task', postData);
  
      if (response.status === 200) {
        if (taskType !== true) {
          const newBalance = balance + reward;
          setBalance(newBalance);
          setTaskInfo(`You have claimed ${reward} coins!`);
          setShowModal(true);
        } else {
          setTaskInfo(`Task sent in moderation`);
          setShowModal(true);
        }
  
        const tasksResponse = await axios.get(`${baseURL}/api2/tasks/${userId}`);
        const loadedTasks = tasksResponse.data.tasks;
        setTasks(loadedTasks);
        setFilterTasks(loadedTasks.filter(task => {
          if (filter === 'all') return true;
          if (filter === 'social activity') return task.type === false;
          if (filter === 'manual verification') return task.type === true;
        }));
  
        return true;
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Ошибка при выполнении задачи:', error);
    }
  };
  
  
  
  
  
  

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConnectWallet = async () => {
    try {
      let wallet = await tonConnectUI.connectWallet();
      await axios.post(`${baseURL}/api2/user/update-wallet`, {
        user_id: userId,
        wallet: toUserFriendlyAddress(wallet.account.address)
      });
    } catch (error) {
      console.error('Ошибка подключения кошелька:', error);
    }
  };

  const handleWalletClick = () => {
    setWalletModalVisible(true);
  };

  const handleCopyWallet = () => {
    if (userFriendlyAddress) {
      navigator.clipboard.writeText(userFriendlyAddress);
      setWalletModalVisible(false);
    }
  };

  const handleDisconnectWallet = async () => {
    try {
      await tonConnectUI.disconnect();
      setWalletModalVisible(false);
    } catch (error) {
      console.error('Ошибка отключения кошелька:', error);
    }
  };

  const maskWallet = (address) => {
    if (!address) return '';
    return `${address.slice(0, 3)}****${address.slice(-3)}`;
  };

  const closeWalletModal = () => {
    setWalletModalVisible(false);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProfileEditClick = () => {
    setProfileEditModalVisible(true);
  };

  const closeProfileEditModal = () => {
    setProfileEditModalVisible(false);
  };

  // Обработка статусов задач
  const getButtonClass = (status) => {
    if (status === null) return '';
    if (status === 'success') return 'success'; // 1 - success (зеленая кнопка)
    if (status === 'in check') return 'process'; // 0 - in check (желтая кнопка)
    if (status === 'decline') return 'decline'; // 2 - decline (красная кнопка)
  };

  useEffect(() => {
    const sortedTasks = tasks.sort((a, b) => {
      if (a.status === 'success' && b.status !== 'success') return 1;
      if (a.status !== 'success' && b.status === 'success') return -1;
      return 0;
    });
    setFilterTasks(sortedTasks.filter(task => {
      if (filter === 'all') return true;
      if (filter === 'social activity') return task.type === false;
      if (filter === 'manual verification') return task.type === true;
    }));
  }, [tasks, filter]);
  

  const filterTaskHandle = (filtertype) => {
    console.log(filter)
    setFilterTasks(tasks.filter(task => {
      if (filtertype === 'all') return true;
      if (filtertype === 'social activity') return task.type == false;
      if (filtertype === 'manual verification') return task.type == true;

    }))
  }

  return (
  <TonConnectUIProvider manifestUrl="https://jettocoinwebapp.vercel.app/tonconnect-manifest.json">
    <div className="container">
      <div className='ttt'>
        <p>{t.someText}</p>
      </div>
      <div className='headerr'>
        <div className='nae'>
          <span className='nameava'>
            <span className='imgheader'>
              <Link to='/stylesy'><img src={avatar} alt='Avatar' /></Link>
              <p>{username}</p>
            </span>
            <span className='frenhead'>
              <p>{referralCount} {t.friends}</p>
            </span>
          </span>
          <span className='headbtns'>
            {wallet ? (
              <div className='wallet-container'>
                <button className='wallet-button' onClick={handleWalletClick}>
                  {maskWallet(userFriendlyAddress)}<img src={wltlogo} alt='' />
                </button>
                <div className='coins'>
                  <p>{balance ? balance.toLocaleString() : '0'} {t.coins}</p>
                </div>
              </div>
            ) : (
              <div className='wallet-container'>
                <button onClick={handleConnectWallet} className="wallet-button">
                  {t.connectWallet}
                </button>
                <div className='coins'>
                  <p>{balance ? balance.toLocaleString() : '0'} {t.coins}</p>
                </div>
              </div>
            )}
          </span>
        </div>
      </div>

      <div className='cher'></div>

      <Modal show={showModal} onClose={handleCloseModal} taskInfo={taskInfo} />

      {walletModalVisible && (
        <div className="modal2">
          <div className="modal-content">
            <button onClick={handleCopyWallet}>{t.copy}</button>
          </div>
        </div>
      )}

        {profileEditModalVisible && (
          <div className='profile-edit-modal'>
            <input type="text" value={username} onChange={handleUsernameChange} placeholder="Enter new username" />
            <input type="file" accept="image/*" onChange={handleAvatarChange} />
            <button onClick={closeProfileEditModal}>{t.close}</button>
          </div>
        )}

        {tasksVisible && (
          <div className='tasks'>
            <h1>
              {t.tasks}
              <button onClick={handleHideTasks} className='close-btn'>×</button>
            </h1>
            <p>{notifyText}</p>
          </div>
        )}

        <div className={`maincontent mainheight pad ${!tasksVisible ? 'expanded' : ''}`}>
          <div className='switchfix'>
            <div className='switches'>
              <button className='btn1'>{t.tasks}</button>
              <Link to='/leaders'><button className='btn2'>{t.leaders}</button></Link>
            </div>

            <div className='miniswitch'>
              <button className={`minibtn ${filter === 'all' ? 'minibtnactive' : ''}`} onClick={() => {
                setFilter('all')
                filterTaskHandle('all')
              }}>{t.all}</button>
              <p className='ras'>|</p>
              <button className={`minibtn ${filter === 'social activity' ? 'minibtnactive' : ''}`} onClick={() => {
                setFilter('social activity')
                filterTaskHandle('social activity')
              }}>{t.socialActivity}</button>
              <p className='ras'>|</p>
              <button className={`minibtn ${filter === 'manual verification' ? 'minibtnactive' : ''}`} onClick={() => {
                setFilter('manual verification')
                filterTaskHandle('manual verification')
              }}>{t.manualVerification}</button>
            </div>
          </div>

          <animated.div
            className='switchcontent'
            ref={scrollRef}
            style={{ transform: springProps.y.to(y => `translateY(${y}px)`) }}
          >
            {filteredTasks.map(task => (
              <div className='tasking' key={task.id}>
                <img className='tskimg' src={`https://app.jettonwallet.com/api2/download/storage/${task.img}`} alt='Task' />
                <div className='tskk'>
                  <p className='tsk'>{task.text}</p>
                </div>
                <div className='valuetask'>
                <button
  className={`claimbtn ${getButtonClass(task.status)} ${completedTasks.includes(task.id) ? `${t.inactive}` : ''}`}
  onClick={() => handleClaimClick(task.id, task.type, task.cost, task.link)}
  disabled={completedTasks.includes(task.id) || task.status === `in check` || task.status === `succes`}
>
  <p>{task.status === `success` ? `${t.claimed}` : task.status === `in check` ? `${t.Processing}` : task.status === `decline` ? `${t.Declined}`: `${task.cost} ${t.toclaim}`}</p>
</button>

                </div>
              </div>
            ))}
          </animated.div>

          <div className={`blur-overlay ${showModal || walletModalVisible || profileEditModalVisible ? 'show' : ''}`} />

        </div>
        <div className='fot'>
          <div className='fotcont'>
            <Link to='/'><button className='activebtn'><img src={fotlogo} alt='Home' /></button></Link>
            <Link to='/Contact'><button ><img src={fotlogo2} alt='Contact' /></button></Link>
            <Link to='/about'><button><img src={fotlogo3} alt='About' /></button></Link>
          </div>
        </div>
      </div>
    </TonConnectUIProvider>
  );
}

export default Home;